//// 代码由余洋编写
import { MapContainer, TileLayer ,Popup, Circle,Marker,Polyline,Tooltip} from "react-leaflet";
import { LatLng } from "leaflet";
import "leaflet/dist/leaflet.css";
import "./App.css";
import Map from "./components/Map";
import GuPlayer from "./components/gugu";
import { useEffect, useState } from "react";
import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
function App() {
  const position = new LatLng(28.574786, 119.341735);
  const [showGuPlayer, setShowGuPlayer] = useState(false);
  const handleButtonClick = () => {
    setShowGuPlayer(true);
  };
  return (
<>
    <div className="App">

    <ToastContainer />
      <MapContainer 
      center={position} 
      zoom={18}
      whenReady={() => {
        console.log("TEST");
      }}
      >
        <Map></Map>
        
        {showGuPlayer && <GuPlayer />}
      </MapContainer>
      <button onClick={handleButtonClick}>开始播放</button>
      <p style={{ fontSize: "12px", marginTop: "10px", color: "red" }}>版权所有 ©️ 2023 余洋 当前版本：2.1.1 浙ICP备2022018353号</p>
    </div>
    </>
  );
}
export default App;