const Raod_g4012tolj_point = [
    {
        "lan": 27.997321,
        "lat": 119.625306,
        "text": "检测汽车开始启动",
        "img": "https://3d.mikuos.com/pic/kechuang/4a99a297895c0539f4f44cf6a1ad8dfb.jpg",
        "safe": "橙色警告"
    },
    {
        "lan": 27.998796,
        "lat": 119.628796,
        "text":"中间分隔带的路缘石破损",
        "img": "https://3d.mikuos.com/pic/20190609211441_5576.png",
        "safe": "黄色警告"

    },
    {
        "lan": 27.999628,
        "lat": 119.629601,
        "text": "桥梁结构存在安全隐患（路面坑洞、伸缩缝、上部结构缺损）",
        "img": "https://3d.mikuos.com/pic/kechuang/6363667748487162506450099.jpg",
        "safe": "黄色警告"
    },
    {
        "lan": 28.003147,
        "lat": 119.644948,
        "text":"左侧公交站台破损",
        "img":"https://3d.mikuos.com/pic/kechuang/20181029081023_8334.jpg",
        "safe": "附近路段检测合格"

    },
    {
        "lan": 28.015115,
        "lat": 119.650549,
        "text":"中间减速带破损",
        "img":"https://3d.mikuos.com/pic/kechuang/00300109089_8ada4985.png",
        "safe": "附近路段检测合格"

    },
    {
        "lan": 28.019181,
        "lat": 119.651686,
        "text":"左侧路面有大面积的龟裂",
        "img": "https://3d.mikuos.com/pic/kechuang/zKpj-hkmwytq0419359.jpg",
        "safe": "附近路段检测合格"

    },
    {
        "lan": 28.02344,
        "lat": 119.654105,
        "text":"边沟严重堵塞",
        "img":"https://3d.mikuos.com/pic/kechuang/W020200402561291302099.png",
        "safe":"附近路段检测合格"

    },
    {
        "lan": 28.030055,
        "lat": 119.656208,
        "text":"路面严重破损",
        "img":"https://3d.mikuos.com/pic/kechuang/f150dfc416cf787167f68f497597b75f.jpg",
        "safe":"附近路段检测合格"

    },
    {
        "lan": 28.036733,
        "lat": 119.656278,
        "text":"警示桩缺失",
        "img":"https://3d.mikuos.com/pic/kechuang/11111.png",
        "safe":"附近路段检测合格"

    }



]

export default Raod_g4012tolj_point ;