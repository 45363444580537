import {TileLayer ,Polyline,Tooltip,Marker,Popup} from "react-leaflet";
import Raod_g4012tolj from "../data/Roads";
import L from "leaflet";
import Raod_g4012tolj_point from "../data/Point";
const icon = L.icon({
  iconSize: [25, 41],
  iconAnchor: [10, 41],
  popupAnchor: [2, -40],
  iconUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-icon.png",
  shadowUrl: "https://unpkg.com/leaflet@1.6/dist/images/marker-shadow.png"
});
//let speed_time = 1000;
function Map() {

  const listItems = Raod_g4012tolj_point.map((number, index) =>
  <Marker icon={icon} position={[Raod_g4012tolj_point[index]["lan"],Raod_g4012tolj_point[index]["lat"]]} ></Marker>
  );
return(
  <>
  <TileLayer
        url="https://webst01.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=6&x={x}&y={y}&z={z}"
      />
  <Polyline 
    pathOptions={{
      color: 'red',
      weight:5
  }} 
    eventHandlers={{
      click: (e) => {
        console.log('marker clicked')
        console.log(e.latlng);
        //alert(e.latlng);
      },
  }}
  positions={Raod_g4012tolj}>
  
  </Polyline>
  {listItems}
  </>
)
}

export default Map;