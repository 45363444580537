import React from 'react';
import { LeafletTrackingMarker } from "react-leaflet-tracking-marker";
import airplaneIcon from "./car.png";
import Raod_g4012tolj from "../data/Roads";
import L from 'leaflet'
import { useEffect, useState } from "react";
import Raod_g4012tolj_point from "../data/Point";
import {  toast } from 'react-toastify';
//LBX And YY forever
const icon = L.icon({
    iconSize: [50, 25],
    popupAnchor: [2, -20],
    iconUrl: airplaneIcon
});
const computeDistance = (pointA: { lat: number, lng: number }, pointB: { lat: number, lng: number }) => {
    const lat1 = pointA.lat;
    const lng1 = pointA.lng;
    const lat2 = pointB.lat;
    const lng2 = pointB.lng;
    // Compute the distance using the Haversine formula
    const R = 6371e3; // metres
    const φ1 = (lat1 * Math.PI) / 180;
    const φ2 = (lat2 * Math.PI) / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lng2 - lng1) * Math.PI / 180;

    const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d;
};
const latLng = (point: [number, number]) => {
    return {
        lat: point[0],
        lng: point[1]
    };
};
let cursor = 0;
let cursor_gu = 0;
function GuPlayer() {
    const [currentTrack, setCurrentTrack] = useState({});
    const [distance, setDistance] = useState(0);
    const [duration, setDuration] = useState(0);
    const [speed, setSpeed] = useState(60); // 初始速度为 60km/h
    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (currentTrack) {
            const nextTrack = Raod_g4012tolj[cursor + 1];
            if(Raod_g4012tolj[cursor][0]==Raod_g4012tolj_point[cursor_gu]["lan"]&&Raod_g4012tolj[cursor][1]==Raod_g4012tolj_point[cursor_gu]["lat"]){
                const customId = Raod_g4012tolj_point[cursor_gu]["lan"]+Raod_g4012tolj_point[cursor_gu]["lat"];

           
                toast.warn(
                <>
                    <img src={Raod_g4012tolj_point[cursor_gu]["img"]} alt="" style={{maxWidth: "100%"}} />
                    <div>{Raod_g4012tolj_point[cursor_gu]["text"]}</div>
                    <div>纬度经度{Raod_g4012tolj_point[cursor_gu]["lan"]},{Raod_g4012tolj_point[cursor_gu]["lat"]}</div>
                </>
                ,{
                    autoClose: 2500,
                    toastId: customId,
                    position: "top-left",
                  });
                  const lbxlove = Raod_g4012tolj_point[cursor_gu]["lan"]+Raod_g4012tolj_point[cursor_gu]["lat"]+Raod_g4012tolj_point[cursor_gu]["lat"];
                  toast.warn(
                    <div>安全系数等级：{Raod_g4012tolj_point[cursor_gu]["safe"]}</div>
                    ,{
                        autoClose: 3000,
                        toastId: lbxlove,
                        position: "top-right",
                    }
                  );



                if(cursor_gu<Raod_g4012tolj_point.length-1){
                    cursor_gu+=1;
                }
            }
            if (!nextTrack) {
                return;
            }           
            const currentTrack = Raod_g4012tolj[cursor];
            const pointA = { lat: currentTrack[0], lng: currentTrack[1] };
            const pointB = { lat: nextTrack[0], lng: nextTrack[1] };
            const dist = computeDistance(pointA, pointB);

            setDistance(dist);
            setDuration(dist / speed);

            interval = setInterval(() => {
                cursor += 1;
                setCurrentTrack(Raod_g4012tolj[cursor]);
            }, duration * 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [currentTrack]);
    const handleSpeedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newSpeed = parseInt(e.target.value, 10);
        setSpeed(newSpeed);
    };

    return(

        <>
            <LeafletTrackingMarker
            icon={icon}
            position={Raod_g4012tolj[cursor]}
            previousPosition={Raod_g4012tolj[cursor-1]}
            duration={duration*1000}
            keepAtCenter={true}
            />
            
        </>
        )
        }
        

export default GuPlayer;